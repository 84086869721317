<template>
  <b-form-group label-cols-sm="1" label="Payment methods:"
    label-for="id-pmethods-field" label-align-sm="right" v-slot="{ ariaDescribedby }">
    <b-input-group style="max-width: 800px;">
      <b-form-select
        id="id-pm-type"
        v-model="formData.pmTypeSelected"
        :options="pmtd"
        placeholder="payment method type"
        @keyup.enter="handleAddPaymentMethod()" />
      <b-form-input
        id="id-pm-name"
        v-model="formData.pmNameEntered"
        placeholder="payment method name"
        @keyup.enter="handleAddPaymentMethod()" />
      <b-form-input
        id="id-pm-text"
        v-model="formData.pmTextEntered"
        placeholder="payment method text"
        @keyup.enter="handleAddPaymentMethod()" />
      <b-form-checkbox
        id="id-pm-disabled"
        v-model="formData.pmDisabledEntered"
        @keyup.enter="handleAddPaymentMethod()"
        button-variant="primary">disabled</b-form-checkbox>
      <b-input-group-append>
        <b-button
          variant="outline-info"
          @click="handleAddPaymentMethod()">Add
        </b-button>
      </b-input-group-append>
    </b-input-group>
    <b-list-group style="max-width: 800px;">
      <b-list-group-item
        v-for="(occ, ndx) in pmList"
        :key="ndx"
        class="d-flex justify-content-between align-items-center">
        {{ occ.type }} / {{ occ.name }} / {{ occ.text }} / {{ occ.disabled }}
        <b-button
          variant="outline-info"
          @click="handleRemovePaymentMethod(ndx)">Remove
        </b-button>
      </b-list-group-item>
    </b-list-group>
  </b-form-group>
</template>

<script>
import { PAYM_METHOD_TYPE_DEFS as PMTD } from '@/services/glapi/constants'

const __getDefaultFormData = () => {
  return {
    pmTypeSelected: PMTD.PREF_PAYM_PROVIDER,
    pmNameEntered: '',
    pmTextEntered: '',
    pmDisabledEntered: false
  }
}

export default {
  name: 'gl-payment-widget',
  version: '0.0.1',
  props: {
    initialData: {
      type: Object,
      default: () => null
    } // initialData
  }, // props
  data () {
    return {
      pmtd: Object.keys(PMTD).map((key) => { return { 'value': PMTD[key], 'text': key } }),
      pmList: this.initialData?.pmList?.slice(0) ?? [],
      formData: __getDefaultFormData()
    }
  }, // data
  watch: {
    // TODO: check if necessary
    initialData: {
      handler: function (nv, ov) {
        console.log('TRACE-REMOVE: GlPaymentWidget.initialData.watch.handler: nv, ov is: ', nv, ov)
        this.pmList = Object.assign({}, nv.pmList)
        // this.$set(this.formData, 'pmList', nv.pmList.slice(0))
      },
      deep: true
    } // initialData
  }, // watch
  mounted () {
    console.log('TRACE-REMOVE: GlPaymentWidget.mounted: this.initialData is: ', this.initialData)
  }, // mounted
  methods: {
    /* ----- public methods ----- */
    getPMList () {
      return this.pmList
    }, // getSignAreaList
    /* ----- private methods ----- */
    // handleLoadFile (e) {
    //   var reader = new FileReader()
    //   reader.readAsDataURL(e.target.files[0])
    //   // var _file = e.target.files[0]
    //   reader.onload = (e) => {
    //     // this.imageSrc = e.target.result
    //     this.formData.pdfSrc = e.target.result
    //   }
    // }, // handleLoadFile
    handleAddPaymentMethod () {
      console.log('TRACE-REMOVE: pmList is: ', this.pmList)
      console.log('TRACE-REMOVE: pmList.length is: ', this.pmList.length)
      // TODO: name MUST be unique!
      if (this.pmList.length > 4) {
        this.$bvToast.toast('Up to 5 payment methods allowed!')
      } else if (this.pmList.some((el) => el.name === this.formData.pmNameEntered)) {
        this.$bvToast.toast('The name of the payment method must be unique!')
      } else {
        if (this.formData.pmTypeSelected && this.formData.pmNameEntered) {
          this.pmList.push(
            {
              name: this.formData.pmNameEntered,
              type: this.formData.pmTypeSelected,
              text: this.formData.pmTextEntered,
              disabled: this.formData.pmDisabledEntered
            })
          this.formData = __getDefaultFormData()
        }
      }
    }, // handleAddSignArea
    handleRemovePaymentMethod (index) {
      this.pmList.splice(index, 1)
    } // handleRemovePaymentMethod
    /* ----- utility methods ----- */
  }, // methods
  components: {
  }
}
</script>

<style lang="scss" scoped>
</style>
